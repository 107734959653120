import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Home from './page/Home';
import Login from './page/Login';
import LoadingSpinner from './components/LoadingSpinner/LoadingSpinner';
import QuestionPage from './components/QuestionPage/QuestionPage';
function App() {

  const [currentUser, setCurrentUser] = useState(null)
  const [loadingAuthState, setLoadingAuthState] = useState(true);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setTimeout(() => {   // Introduce a delay
        if (user) {
          setCurrentUser(user);
        } else {
          setCurrentUser(null);
        }
        setLoadingAuthState(false);
      }, 0);  // 2 seconds delay
    });

    return () => unsubscribe();
  }, []);

  // If still determining the auth state, return a loading spinner or null
  if (loadingAuthState) return <LoadingSpinner />;

  return (
    <Router>
      <div>
        <section>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/home" element={<PrivateRoute><Home /></PrivateRoute>} />
            <Route path="/question/:questionId" element={<PrivateRoute><QuestionPage /></PrivateRoute>} />
          </Routes>
        </section>
      </div>
    </Router>
  );
}

function PrivateRoute({ children }) {
  const location = useLocation();
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
    // Redirect to login and pass the current location in state
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

export default App;