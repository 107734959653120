// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBjPJP74CGnUQ3pHjhgQ0bZuaZ0XERFSDo",
  authDomain: "fir-authtutorial-f697c.firebaseapp.com",
  projectId: "fir-authtutorial-f697c",
  storageBucket: "fir-authtutorial-f697c.appspot.com",
  messagingSenderId: "468054722289",
  appId: "1:468054722289:web:987e7dbe42630291e20b50"
};

const app = initializeApp(firebaseConfig);

export default app;