import React from 'react';
import ClipLoader from "react-spinners/MoonLoader"
import { css } from "@emotion/react";



function LoadingSpinner() {
  return <ClipLoader color={"#36d7b7"} loading={true} />;
}

export default LoadingSpinner;