import { useEffect, useState } from 'react';
import { getAuth } from 'firebase/auth';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import './Leaderboard.css';

function Leaderboard() {
    const [leaderboardData, setLeaderboardData] = useState([]);
    const [loadingAuthState, setLoadingAuthState] = useState(true);

    const displayedData = leaderboardData.length >= 3 ? leaderboardData.slice(3) : leaderboardData;

    useEffect(() => {
        const fetchData = async () => {
            const auth = getAuth();
            const token = await auth.currentUser.getIdToken(true);

            try {
                const backendUrl = process.env.REACT_APP_BACKEND_URL;
                const response = await fetch(`${backendUrl}/leaderboard`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                const data = await response.json();
                setLeaderboardData(data);
                setLoadingAuthState(false);
                console.log(data)
            } catch (error) {
                console.error("Error fetching leaderboard data:", error);
            }
        };

        fetchData();
    }, []);

    // If still determining the auth state, return a loading spinner or null
    if (loadingAuthState) return <LoadingSpinner />;

    return (
        <div className="container">
            <div className="title">
                <h1>Leaderboard</h1>
            </div>
            <div className="upper-block">
                {renderPodium()}
            </div>
            <div className="leaderboard">
                {displayedData.map((entry, index) => (
                    <div key={index} className="entry">
                        <div className="avatar">
                            <img src={entry.Picture ? entry.Picture : "https://i.imgur.com/MzEcdr0.png"} alt={entry.Name} referrerPolicy="no-referrer" />
                        </div>
                        <div className="player-info">
                            <h2>{entry.Name}</h2>
                        </div>
                        <div className="score">{entry.Score}</div>
                    </div>
                ))}
            </div>
        </div>
    );

    function renderPodium() {
        if (leaderboardData.length >= 3) {
            return (
                <div className="podium">
                    <div className="second-place">
                        <img className="avatar-second" src={leaderboardData[1].Picture} alt={leaderboardData[1].Name} referrerPolicy="no-referrer" />
                        <p className="name">{leaderboardData[1].Name}</p>
                        <p className="score">{leaderboardData[1].Score}</p>
                    </div>
                    <div className="first-place">
                        <img className="avatar-first" src={leaderboardData[0].Picture} alt={leaderboardData[0].Name} referrerPolicy="no-referrer" />
                        <p className="name">{leaderboardData[0].Name}</p>
                        <p className="score">{leaderboardData[0].Score}</p>
                    </div>

                    <div className="third-place">
                        <img className="avatar-third" src={leaderboardData[2].Picture} alt={leaderboardData[2].Name} referrerPolicy="no-referrer" />
                        <p className="name">{leaderboardData[2].Name}</p>
                        <p className="score">{leaderboardData[2].Score}</p>
                    </div>

                </div>
            );
        }
    }

}

export default Leaderboard;