import { getAuth } from 'firebase/auth';
import Leaderboard from '../components/Leaderboard/Leaderboard';
import { useState, useEffect  } from 'react';

function Home() {

  const [jwt, setJwt] = useState(null)

  useEffect(() => {
    const auth = getAuth();
    if (auth.currentUser) {
      auth.currentUser.getIdToken(/* forceRefresh */ true).then((idToken) => {
        setJwt(idToken);
      });
    }
  }, []);

  console.log({jwt})

  return (
    <div>
       { //Welcome {user ? user.displayName || user.email : ""}! You token is {jwt} 
       }
      <Leaderboard />
    </div>
  );
}

export default Home;
