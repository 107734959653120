import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import Confetti from 'react-confetti';
import './QuestionPage.css';

function QuestionPage() {
    const [question, setQuestion] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isModalOpen, setModalOpen] = useState(false);

    const [selectedAnswers, setSelectedAnswers] = useState([]);
    const [isAnswered, setIsAnswered] = useState(false);
    const [penaltyDuration, setPenaltyDuration] = useState(0);
    const [isProcessing, setIsProcessing] = useState(false);

    const [feedback, setFeedback] = useState("")

    const questionFontSize = getFontSizeForQuestion(question);

    const navigate = useNavigate();

    const { questionId } = useParams();

    useEffect(() => {
        // Fetch the question data when the component mounts and when questionId changes
        const fetchQuestion = async () => {
            const auth = getAuth();
            const token = await auth.currentUser.getIdToken(true);
            try {
                const backendUrl = process.env.REACT_APP_BACKEND_URL;
                const response = await fetch(`${backendUrl}/question/${questionId}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok' + response.statusText);
                }
                const data = await response.json();
                console.log(data)
                setQuestion(data);
                if (data.playerStatus.hasAnswered === true) {
                    setIsAnswered(true)
                }
                else if (data.playerStatus.remaningPenaltyDuration > 0) {
                    setPenaltyDuration(data.playerStatus.remaningPenaltyDuration);
                }
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchQuestion();
    }, [questionId]);

    useEffect(() => {
        if (penaltyDuration > 0) {
            const timer = setTimeout(() => {
                setPenaltyDuration(penaltyDuration - 1);
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [penaltyDuration]);

    const handleChoiceChange = (choiceId) => {
        if (selectedAnswers.includes(choiceId)) {
            setSelectedAnswers(selectedAnswers.filter(id => id !== choiceId));
        } else {
            setSelectedAnswers([...selectedAnswers, choiceId]);
        }
    };

    const handleScqChange = (e) => {
        const value = e.target.value;

        setSelectedAnswers([value]);

    };

    const handleSubmitAnswers = async () => {
        setIsProcessing(true); // Disable the button as answer is being submitted
        const auth = getAuth();
        const token = await auth.currentUser.getIdToken(true);

        try {
            const backendUrl = process.env.REACT_APP_BACKEND_URL;
            const response = await fetch(`${backendUrl}/question/${questionId}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ answers: selectedAnswers }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok' + response.statusText);
            }

            const data = await response.json();
            console.log(data)

            if (!data.isAnswered && data.penaltyDuration) {
                // If answer is wrong and a penalty is applied
                setPenaltyDuration(data.penaltyDuration);
                setFeedback(getRandomInorrectFeedback())
            }
            else {
                setIsAnswered(true)
                setFeedback(getRandomCorrectFeedback())
                setTimeout(() => {
                    navigate('/home');
                }, 10000);
            }
        } catch (error) {
            console.error("Error:", error);
            // TODO: Handle error accordingly.
        }
        finally{
            setIsProcessing(false);
        }
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;
    if (!question) return <p>No question found!</p>;

    return (
        <div className='container-root'>
            <div className='container-question'>
                {renderQuestion()}
            </div>
            <div className='container-responses'>
                {renderResponses()}
            </div>
            <div className='container-validation'>
                {renderValidation()}
            </div>
        </div>
    );

    function renderQuestion() {
        if (question.imageURL) {
            return (
                <>
                    <h2 className="question-text">{question.text}</h2>
                    <div>
                        <img
                            src={question.imageURL}
                            onClick={() => setModalOpen(true)}
                            className="thumbnailImage"
                        />
                        {isModalOpen && (
                            <div
                                className="modalContainer"
                                onClick={() => setModalOpen(false)}
                            >
                                <img
                                    src={question.imageURL}
                                    className="modalImage"
                                />
                            </div>
                        )}
                    </div>
                </>
            );
        }
        return <h2 className="question-text" style={{ fontSize: questionFontSize }}>{question.text}</h2>;
    }

    function renderResponses() {
        if (question.type === "mcq") {
            return (
                <ul className="responses">
                    {Array.isArray(question.choices) && question.choices.length > 0 && question.choices.map(choice => (
                        <li key={choice.Id}>
                            <button
                                onClick={() => handleChoiceChange(choice.Id)}
                                className={selectedAnswers.includes(choice.Id) ? "response-button active" : "response-button"}
                            >
                                {choice.text}
                            </button>
                        </li>
                    ))}
                </ul>
            );
        } else {
            return (
                <input className="scq-input"
                    type="text"
                    placeholder="..."
                    onChange={handleScqChange}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleSubmitAnswers();
                        }
                    }}
                />
            )
        }
    }

    function renderValidation() {
        if (!isAnswered) {
            if (penaltyDuration > 0) {
                return (
                    <div className='answer wrong'>
                        <p> {feedback} </p>
                        <p> 🔒 Temps de pénalité: {penaltyDuration}s 🔒</p>
                    </div>
                );
            }
            return (
                <button onClick={handleSubmitAnswers} disabled={penaltyDuration > 0 || isAnswered || isProcessing}>VALIDER</button>
            );
        }
        return (
            <div className="answer correct">
                <p> {feedback} </p>
                <Confetti />
            </div>
        );
    }


}

const correctFeedbacks = [
    "Excellent, c'est juste! 🌟👏",
    "Parfait! Réponse correcte! 🥳✨",
    "Bravo, tu as visé juste! 🎯👍",
    "Correct! Bien joué! 🙌🎊",
    "C'est ça! Tu as tout bon! 🌈🎈",
    "Woah tu as trouvé! 🚀😊",
    "Formidable! Tu as trouvé! 🌟💫",
];

function getRandomCorrectFeedback() {
    const index = Math.floor(Math.random() * correctFeedbacks.length);
    return correctFeedbacks[index];
}

const incorrectFeedbacks = [
    "Erreur! 🙁",
    "Malheureusement non. 😔",
    "Raté... 😞",
    "Ce n'est pas ça. 😢",
    "Faux, essaie encore. 😖",
    "Pas tout à fait juste. 😟",
    "Pas la bonne réponse. 😫"
];

function getRandomInorrectFeedback() {
    const index = Math.floor(Math.random() * incorrectFeedbacks.length);
    return incorrectFeedbacks[index];
}




const getFontSizeForQuestion = (questionText) => {
    if (!questionText) {
        return "20px";  // default size or whatever size you deem appropriate
    }
    const length = questionText.text.length;
    if (length < 50) {
        return "24px";  // pour des questions courtes
    } else if (length < 100) {
        return "20px";  // pour des questions de taille moyenne
    } else {
        return "18px";  // pour des questions longues
    }
}


export default QuestionPage