import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';  // ensure you've installed the firebase npm package
import { useNavigate, useLocation } from 'react-router-dom'; // Import the useNavigate hook
import app from '../firebaseConfig';
import logo from './logo.png'
import './Login.css'

function Login() {

    const location = useLocation();
    const navigate = useNavigate();

    const handleGoogleLogin = async () => {
        const auth = getAuth(app);
        const provider = new GoogleAuthProvider();

        try {
            await signInWithPopup(auth, provider);
            const from = location.state?.from?.pathname || '/home'; //redirects user to previous page if exists, else /home
            navigate(from);
        } catch (error) {
            console.error("Error signing in with Google:", error);
        }
    };

    return (
        <div className="container">
            <div className="upper-block"></div>
            <img src={logo} alt="Logo" loading='lazy' className='image'></img>
            <div className="lower-block">
                <button className="google-login-btn w-button" onClick={handleGoogleLogin}>Sign in with Google</button>
            </div>
        </div>
    );
}

export default Login;
